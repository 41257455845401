html {
    height: 100%;
    width: 100%;
    position: fixed;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

* {
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
}

input {
    width: 100%;
    width: calc(100% - 32px);
    max-width: 368px;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 14px;
    margin-bottom: 16px;
}

input:focus {
    background-color: #fcfcfc;
}

button {
    width: 100%;
    max-width: 400px;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
    border: 1px solid #dddddd;
    margin-bottom: 16px;
}

button p {
    margin: 0;
    line-height: 24px;
}

.full-width {
    width: 100%;
}

.content-container {
    width: 80%;
    max-width: 368px;
    padding: 16px;
    margin: 0px auto;
}

.terms-outer-container {
    width: 100%;
    max-width: 400px;
    margin-bottom: 16px;
}

.terms-outer-container input {
    float: left;
    width: 40px;
    margin: 0;
    margin-top: 5px;
}

.terms-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.terms-container p {
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.info-text {
    width: 100%;
    width: calc(100% - 32px);
    padding: 16px;
    max-width: 400px;
    text-align: center;
}

.all-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.upper-third {
    display: flex;
    flex: 1;
    align-self: stretch;
    align-items: flex-end;
    justify-content: center;
    background-color: #00794f;
    border-bottom-left-radius: 200% 150px;
    border-bottom-right-radius: 200% 150px;
}

.upper-third .title-container {
    width: 100%;
    width: calc(100% - 32px);
    padding: 16px;
    max-width: 400px;
    text-align: center;
    margin-bottom: 16px;
}

.upper-third .title-container .title-image-container {
    background: #ffffff;
    border-radius: 50%;
    padding: 8px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 16px;
}

.upper-third .title-container .title-image-container .title-image {
    width: 70px;
}

.upper-third .title-container h1 {
    color: #ffffff;
    font-size: 32px;
    margin: 0;
}

.lower-two-thirds {
    flex: 2;
    width: 100%;
    text-align: center;
    overflow: auto;
}

.bottom-placeholder {
    width: 100%;
    height: 100px;
}

.language-container {
    width: calc(100% - 32px);
    max-width: 368px;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 14px;
    margin-bottom: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
}

.language-flag {
    height: 30px;
    margin-right: 16px;
    border: 1px solid #dddddd;
}

.language-label {
    color: #333;
    margin: 0;
}

.language-container-list {
    position: absolute;
    padding: 8px;
    top: 0;
    right: 0;
    background-color: #ffffff;
    border-left: 1px solid #f8f8f8;
    height: 100%;
    overflow: auto;
}

.language-selection-icon {
    position: absolute;
    right: 16px;
    color: #333333;
    font-size: 20px;
    margin: 0;
}

.language-container-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #f8f8f8;
    cursor: pointer;
    margin-top: 8px;
    padding-bottom: 8px;
}

.language-container-list-item .language-label {
    color: #333;
}

.download-link-image{
    width: 140px;
}
